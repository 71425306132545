import styled from 'styled-components'
import Layout from '../layouts/default'
import Router from 'next/router'
import { LargeGreenButton } from '../components/Buttons/LargeButtons'

export default function Custom404() {
  return (
    <Wrapper className="bg_grayBackground mh">
      <Main>
        <Illustration src="/images/404.svg" />
        <Title>找不到此頁面！</Title>
        <SubTitle>呃...也有可能是需要登入。</SubTitle>
        <RedirectButton onClick={() => Router.push('/')}>返回首頁</RedirectButton>
      </Main>
    </Wrapper>
  )
}

Custom404.withLayout = (pageComponent) => <Layout withFooter={true}>{pageComponent}</Layout>

const Wrapper = styled.div`
  padding-bottom: 64px;
  background: url(/images/${(props) => (props.success ? 'payment_success_deco' : 'payment_failed_deco')}.svg);
  background-repeat: no-repeat;
  background-size: 100vw 400px;
  background-position: center bottom;
`
const Main = styled.main`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
`
const Illustration = styled.img`
  width: 500px;
  height: 200px;
  margin-top: 72px;
`
const Title = styled.h3`
  font-size: 33px;
  line-height: 40px;
  color: var(--primary);
  margin-top: 32px;
  margin-bottom: 0px;
  font-weight: 600;
`
const SubTitle = styled(Title)`
  font-size: 24px;
  line-height: 32px;
  margin-top: 12px;
`
const RedirectButton = styled(LargeGreenButton)`
  font-size: 17px;
  font-weight: 600;
  padding: 19px 30px;
  margin-top: 72px;
`
